<template>
  <div v-if="getUser.role.slug === 'super-administrateur'">
    <div
      v-if="isLoading"
      class="d-flex justify-content-center mb-1"
      style="padding-top: 30vh"
    >
      <b-spinner
        variant="info"
        style="width: 3rem; height: 3rem"
        class="mr-1"
      />
    </div>
    <div v-else>
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="9" lg="8" md="7">
          <b-card>
            <b-row>
              <!-- User Info: Left col -->
              <b-col
                cols="12"
                xl="6"
                class="d-flex justify-content-between flex-column"
              >
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                      <h4 class="mb-0">
                        {{ wallet.point_focal.responsable_name }}
                      </h4>
                      <feather-icon icon="MapPinIcon" class="mr-75" />
                      <span class="card-text"
                        >Ville : {{ wallet.point_focal.town_name }}</span
                      >
                    </div>
                  </div>
                </div>
                <!-- User Stats -->
              </b-col>

              <!-- Right Col: Table -->
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" md="5" xl="3" lg="4">
          <b-card no-body class="border-primary">
            <b-card-body>
              <div class="mb-0 font-medium-3">
                Solde:
                <span
                  :class="wallet.balance > 0 ? 'text-success' : 'text-danger'"
                >
                  FCFA {{ wallet.balance }}
                </span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-card>
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-2"
          >
            <b-button
              @click="showWalletOperationModal('withdraw')"
              variant="outline-info"
            >
              <span class="text-nowrap font-medium-1">Faire un retrait</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-2"
          >
            <b-button
              @click="showWalletOperationModal('deposit')"
              variant="outline-success"
            >
              <span class="text-nowrap font-medium-1">Faire un dépôt</span>
            </b-button>
          </b-col>
        </b-row>

        <b-table
          ref="refInvoiceListTable"
          :items="wallet.logs"
          responsive
          :fields="tableWalletLogsColumns"
          primary-key="id"
          show-empty
          empty-text="Aucune opération effectuée."
          class="position-relative"
        >
          <!-- Column: Issued Date -->
          <template #cell(balance_before_operation)="data">
            <span
              class="font-small-3"
              :class="
                data.item.balance_before_operation > 0
                  ? 'text-info'
                  : 'text-danger'
              "
            >
              XOF {{ data.item.balance_before_operation }}
            </span>
          </template>
          <template #cell(amount)="data">
            <span class="text-info font-small-3">
              XOF {{ data.item.amount }}
            </span>
          </template>
          <template #cell(balance_after_operation)="data">
            <span
              class="text-info font-small-3"
              :class="
                data.item.balance_after_operation > 0
                  ? 'text-info'
                  : 'text-danger'
              "
            >
              XOF {{ data.item.balance_after_operation }}
            </span>
          </template>
          <template #cell(operation_date)="data">
            <span class="font-small-3">
              {{
                moment(data.item.operation_date)
                  .locale("fr")
                  .format("llll")
                  .split("00:00")[0]
              }}
            </span>
          </template>
          <template #cell(client)="data">
            <span class="font-small-3">
              {{ data.item.client == null ? " " : data.item.client.full_name }}
            </span>
          </template>
          <template #cell(trace)="data">
            <span class="font-small-3">
              {{
                data.item.trace
              }}
            </span>
          </template>
          <template #cell(employee)="data">
            <span class="font-small-3">
              {{
                data.item.employee == null ? " " : data.item.employee.full_name
              }}
            </span>
          </template>
          <template #cell(operation_type)="data">
            <b-badge
              pill
              :variant="`light-${resolveOrderStatusVariant(
                data.item.operation_type
              )}`"
              class="text-capitalize font-small-3"
            >
              {{ resolveStatusText(data.item.operation_type) }}
            </b-badge>
          </template>
        </b-table>
      </b-card>
    </div>
    <b-modal
      id="modal-new-wallet-operation"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Confirmation du paiement du point focal"
    >
      <validation-observer #default="{}" ref="paymentForm">
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @reset.prevent="hideModal"
          @submit.prevent="applyPointFocalWalletOperationAction"
        >
          <validation-provider
            #default="{ errors }"
            designation="amount"
            rules="required"
          >
            <b-form-group label="Montant" label-for="amount">
              <b-form-input
                id="amount-add"
                v-model="payload.amount"
                type="number"
                :state="errors.length > 0 ? false : null"
                trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            designation="amount"
            rules="required"
          >
            <b-form-group label="Désignation" label-for="designation">
              <b-form-input
                id="designation"
                v-model="payload.designation"
                type="text"
                :state="errors.length > 0 ? false : null"
                trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isPaymentLoading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <div v-if="isPaymentLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Confirmer</span>
            </b-button>
            <b-button type="reset" variant="outline-secondary">
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BAlert,
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BForm,
  BFormInput,
  BFormGroup,
  BAvatar,
  BLink,
  BBadge,
  BFormTextarea,
  BCardHeader,
  BCardBody,
  BSpinner,
  BTab,
  BTabs,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, alphaNum, email } from "@validations";

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BSpinner,
    BForm,
    BFormInput,
    BFormGroup,
    BCardHeader,
    BCardBody,
    BAlert,
    BRow,
    BCol,
    BTable,
    BAvatar,
    BLink,
    BBadge,
    BTab,
    BTabs,
    BFormTextarea,
  },
  data() {
    return {
      wallet: null,
      payload: {
        amount: "",
        operation_type: "",
        designation: "",
      },
      required,
      alphaNum,
      isPaymentLoading: false,
      isLoading: true,
      tableWalletLogsColumns: [
        {
          key: "operation_type",
          label: "Type d'opération",
          sortable: true,
          class: "font-small-4",
        },
        {
          key: "amount",
          label: "Montant",
          sortable: true,
          class: "font-small-4",
        },
        {
          key: "balance_before_operation",
          label: "Montant avant l'opération",
          sortable: true,
          class: "font-small-4",
        },
        {
          key: "trace",
          label: "Designation",
          sortable: false,
          class: "font-small-4",
        },
        {
          key: "operation_date",
          label: "Date l'opération",
          sortable: true,
          class: "font-medium-2",
        },
        {
          key: "employee",
          label: "EMPLOYE",
          sortable: true,
          class: "font-medium-2",
        },
        {
          key: "client",
          label: "CLIENT",
          sortable: true,
          class: "font-medium-2",
        },
        {
          key: "balance_after_operation",
          label: "Montant après l'opération",
          sortable: true,
          class: "font-small-4",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
  },
  watch: {},
  created() {
    this.applyGetPointFocalsWalletDetailsAction(this.$route.params.id);
  },
  methods: {
    ...mapActions("offerType", [
      "getOnePointFocalAction",
      "pointFocalWalletOperationAction",
    ]),
    applyPointFocalWalletOperationAction() {
      this.$refs.paymentForm.validate().then((success) => {
        if (success) {
          this.isPaymentLoading = true;
          this.pointFocalWalletOperationAction({
            id: this.wallet.point_focal.id,
            payload: this.payload,
          })
            .then((response) => {
              this.isPaymentLoading = false;
              this.applyGetPointFocalsWalletDetailsAction(
                this.$route.params.id
              );
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Succès",
                    icon: "CheckIcon",
                    text: response.message,
                    variant: "success",
                  },
                },
                {
                  position: "top-center",
                }
              );
              this.$bvModal.hide("modal-new-wallet-operation");
              this.payload = {
                amount: "",
              };
            })
            .catch((error) => {
              console.log(error);
              this.isPaymentLoading = false;
            });
        }
      });
    },
    applyGetPointFocalsWalletDetailsAction(id) {
      this.getOnePointFocalAction(id)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.wallet = response.data;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    hideModal() {
      this.$bvModal.hide("modal-new-wallet-operation");
      this.payload = {
        amount: "",
        designation: "",
      };
    },
    resolveOrderStatusVariant(text) {
      if (text === "deposit") {
        return "success";
      }
      return "danger";
    },
    resolveStatusText: (text) => {
      if (text === "deposit") {
        return "Dépôt";
      }
      return "Retrait";
    },
    showWalletOperationModal(operation_type) {
      this.$bvModal.show("modal-new-wallet-operation");
      this.payload.operation_type = operation_type;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
